import { Modal, useCustomTranslation } from '@holberg/ui-kit';
import cn from 'classnames';
import { ReportSection } from 'components/ReportSection';

import styles from './ReportPreviewModal.module.scss';

export const REPORT_PREVIW_MODAL = 'report-preview-modal';
interface Props {
  visible: boolean;
  onVisibilityChange: () => void;
}

export const ReportPreviewModal: React.FC<Props> = ({
  visible,
  onVisibilityChange
}) => {
  const { t } = useCustomTranslation();

  return (
    <Modal
      className={cn(styles.modal, styles['report-preview-modal'])}
      contentClassName={styles.content}
      visible={visible}
      handleVisible={onVisibilityChange}
      title={t('Report preview')}
      data-testid={REPORT_PREVIW_MODAL}
    >
      <div className={styles.wrap}>
        <ReportSection />
      </div>
    </Modal>
  );
};
