import { compareAsc, compareDesc } from 'date-fns';
import { ApiError } from 'entities/ApiError.entity';
import { Description } from 'entities/Description.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { Report } from 'entities/Report.entity';
import { Study } from 'entities/Study.entity';
import { RealTimeUpdateReceiveMessages } from 'enums/RealTimeUpdateType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { action, computed, makeObservable, observable } from 'mobx';
import { ReportScopeApi } from 'services/API/ReportScope/ReportScopeApi';
import { StudyApi } from 'services/API/Study/StudyApi';
import {
  OpenStudyRTUData,
  RTUManager,
  StudyListUpdatedRTUData
} from 'services/RealTimeUpdatesManager';
import { stores } from 'stores';
import { BaseStore } from 'stores/BaseStore';

export class PatientReportsStore implements BaseStore {
  @observable
  patientReports: Map<Description['descriptionId'], Report> = new Map();

  @observable
  unreportedRecordings: Map<Study['studyId'], Study> = new Map();

  @observable
  patientOverviewLoading!: boolean;

  @observable
  selectedReportedRecordings: Map<Study['studyId'], Study> = new Map();

  @observable
  selectedUnreportedRecordings: Map<Study['studyId'], Study> = new Map();

  @observable
  selectedReports: Map<Description['descriptionId'], Report> = new Map();

  @observable
  expandedRecordingsInReport: Map<
    Description['descriptionId'],
    boolean
  > = new Map();

  @observable
  activeReportId: number | null = null;

  @observable
  focusedRecordingIds?: number[];

  @observable
  lastOpenedUnreportedRecording: OpenStudyRTUData | null = null;

  private currentPatientId: number | null = null;

  constructor() {
    makeObservable(this);
    this.reset();

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.StudyListUpdated,
        callback: (data) => this.updatePatientOverviewData(data)
      }
    ]);
  }

  @action reset() {
    this.patientReports = new Map();
    this.unreportedRecordings = new Map();
    this.patientOverviewLoading = false;
    this.selectedReportedRecordings = new Map();
    this.selectedUnreportedRecordings = new Map();
    this.selectedReports = new Map();
    this.activeReportId = null;
    this.focusedRecordingIds = [];
    this.currentPatientId = null;
    this.lastOpenedUnreportedRecording = null;
  }

  @action
  private updatePatientOverviewData(data: StudyListUpdatedRTUData) {
    const updatedReports = Report.deserializeAsList(data.studyListChanges);
    if (updatedReports[0].studies[0].patientId !== this.currentPatientId)
      return;
    const updatedReportsIds = updatedReports.map(
      (report) => report.description?.descriptionId
    );
    for (const reportId of updatedReportsIds) {
      reportId && this.patientReports.delete(reportId);
    }
    this.filterAndSortReports([...this.getReports, ...updatedReports]);
    this.filterAndSortUnreportedRecordings([
      ...updatedReports.filter((report) => report.description === null),
      Report.deserialize({
        description: null,
        studies: [...this.getUnreportedRecordings]
      })
    ]);
  }

  @action
  async loadpatientOverviewData(patientId: PatientDetails['patientId']) {
    this.selectedReportedRecordings.clear();
    this.selectedUnreportedRecordings.clear();
    this.selectedReports.clear();
    this.currentPatientId = patientId;
    this.patientOverviewLoading = true;
    try {
      const { data } = await ReportScopeApi.loadPatientReports(patientId);
      this.fillPatientOverview(data);
    } catch (e) {
      stores[StoreType.Messages].setGeneralError(
        ApiError.deserializeFromCatch(e)
      );
    } finally {
      this.patientOverviewLoading = false;
    }
  }

  @action
  async createNewReport(recordings: number[]) {
    this.patientOverviewLoading = true;
    try {
      const { data } = await StudyApi.createNewReport(recordings);
      const reports = Report.deserializeAsList(data);
      this.discardSelections();
      const sourceReport = this.getReportByRecording(recordings[0]);
      if (sourceReport === null) {
        //recordings are taken from unreported section to create report
        recordings.forEach((id: number) =>
          this.unreportedRecordings.delete(id)
        );
      }
      //first deleting the report from which recordings are taken to create report
      sourceReport && this.patientReports.delete(sourceReport);
      this.filterAndSortReports([...this.getReports, ...reports]);
      this.activeReportId =
        (sourceReport
          ? reports.filter(
              (report) => report.description?.descriptionId !== sourceReport
            )[0].description?.descriptionId
          : reports[0].description?.descriptionId) || null;
      this.focusedRecordingIds = recordings;
    } catch (e) {
      stores[StoreType.Messages].setGeneralError(
        ApiError.deserializeFromCatch(e)
      );
    } finally {
      this.patientOverviewLoading = false;
    }
  }

  @action
  async moveRecordings(
    patientId: PatientDetails['patientId'],
    recordingIds: number[],
    descriptionId?: Description['descriptionId']
  ) {
    this.patientOverviewLoading = true;
    try {
      const { data } = await ReportScopeApi.moveRecordings(
        patientId,
        recordingIds,
        descriptionId
      );
      const reports = Report.deserializeAsList(data);
      this.discardSelections();

      const sourceReport = this.getReportByRecording(recordingIds[0]); //report from which recordings are dragged
      if (!descriptionId && sourceReport) {
        //when recordings are moved to unreported section from a report
        const movedRecordings = this.patientReports
          .get(sourceReport)
          ?.studies.filter((study) => recordingIds.includes(study.studyId))!;
        this.filterAndSortUnreportedRecordings([
          Report.deserialize({
            description: null,
            studies: [...movedRecordings, ...this.getUnreportedRecordings]
          })
        ]);
      }
      sourceReport && this.patientReports.delete(sourceReport);
      descriptionId && this.patientReports.delete(descriptionId);
      this.filterAndSortReports([...this.getReports, ...reports]);
      if (descriptionId && !sourceReport) {
        //when recordings are moved from unreported section to a report
        recordingIds.forEach((id: number) =>
          this.unreportedRecordings.delete(id)
        );
      }
      descriptionId && this.expandedRecordingsInReport.set(descriptionId, true);
      this.activeReportId = descriptionId || null;
      this.focusedRecordingIds = recordingIds;
    } catch (e) {
      stores[StoreType.Messages].setGeneralError(
        ApiError.deserializeFromCatch(e)
      );
    } finally {
      this.patientOverviewLoading = false;
    }
  }

  @action
  async reOpenDescription(descriptionId: Description['descriptionId']) {
    this.clearActiveStates();
    this.patientOverviewLoading = true;
    try {
      const { data } = await StudyApi.reopenDescription(descriptionId);
      if (data?.descriptionId === descriptionId) {
        this.patientReports.delete(descriptionId);
        this.getReportsByIds([descriptionId]);
        this.activeReportId = descriptionId;
      }
    } catch (e) {
      stores[StoreType.Messages].setGeneralError(
        ApiError.deserializeFromCatch(e)
      );
    } finally {
      this.patientOverviewLoading = false;
    }
  }

  @action
  async deleteReport(descriptionId: Description['descriptionId']) {
    this.clearActiveStates();
    this.patientOverviewLoading = true;
    try {
      const { data } = await StudyApi.deleteDescription(descriptionId);
      if (data) {
        this.filterAndSortUnreportedRecordings([
          Report.deserialize({
            description: null,
            studies: [
              ...this.patientReports.get(descriptionId)!.studies,
              ...this.getUnreportedRecordings
            ]
          })
        ]);
        this.patientReports.delete(descriptionId);
      }
    } catch (e) {
      stores[StoreType.Messages].setGeneralError(
        ApiError.deserializeFromCatch(e)
      );
    } finally {
      this.patientOverviewLoading = false;
    }
  }

  async getReportsByIds(descriptionIds: number[]) {
    this.patientOverviewLoading = true;
    try {
      const { data } = await ReportScopeApi.getReportsById(descriptionIds);
      const reports = Report.deserializeAsList(data);
      this.currentPatientId =
        reports[0].description?.patientId || reports[0].studies[0].patientId;
      this.filterAndSortReports([...reports, ...this.getReports]);
    } catch (e) {
      stores[StoreType.Messages].setGeneralError(
        ApiError.deserializeFromCatch(e)
      );
    } finally {
      this.patientOverviewLoading = false;
    }
  }

  fillPatientOverview(data: any) {
    const patientOverviewData = Report.deserializeAsList(data);
    this.filterAndSortReports(patientOverviewData);
    this.filterAndSortUnreportedRecordings(patientOverviewData);
  }

  private filterAndSortReports(allReports: Report[]) {
    const sortedReports = allReports
      .filter((report: Report) => {
        report.studies = report.studies.sort((a: Study, b: Study) => {
          return compareAsc(
            new Date(a.formattedStudyStart),
            new Date(b.formattedStudyStart)
          );
        });
        return report.description !== null;
      })
      .sort((a: Report, b: Report) => {
        return compareDesc(
          new Date(a.reportStartDateTime),
          new Date(b.reportStartDateTime)
        );
      });
    this.patientReports = new Map();
    sortedReports.forEach((report: Report) =>
      this.patientReports.set(report.description!.descriptionId, report)
    );
  }

  private filterAndSortUnreportedRecordings(allReports: Report[]) {
    const sortedUnreportedRecordings = allReports
      .filter((report: Report) => report.description === null)
      .flatMap((report) => report.studies)
      .sort((a: Study, b: Study) => {
        return compareDesc(
          new Date(a.formattedStudyStart),
          new Date(b.formattedStudyStart)
        );
      });
    this.unreportedRecordings = new Map();
    sortedUnreportedRecordings.forEach((study: Study) =>
      this.unreportedRecordings.set(study.studyId, study)
    );
  }

  isRecordingSelectable(reportId: number | null, recordingId: number) {
    if (reportId) {
      //checks for reported recording
      const report = this.patientReports.get(reportId)!;
      if (
        this.selectedUnreportedRecordings.size ||
        this.selectedReports.size ||
        (this.getSelectedReportedRecordings[0] &&
          this.getReportByRecording(
            this.getSelectedReportedRecordings[0]?.studyId
          ) !== reportId) ||
        report?.description?.isCompleted
      ) {
        return false;
      }
      return true;
    } else {
      //checks for unreported recording
      if (
        this.selectedReportedRecordings.size ||
        this.unreportedRecordings
          .get(recordingId)
          ?.overlappingRecordingIds.some(
            (id) => (this.selectedUnreportedRecordings.get(id) ? true : false)
            //disable if one of the overlapped unreported recording is selected
          )
      ) {
        return false;
      }
      return true;
    }
  }

  getReportByRecording(recordingId: number) {
    for (const report of this.getReports) {
      if (report.studies.some((study) => study.studyId === recordingId)) {
        return report.description!.descriptionId;
      }
    }
    return null;
  }

  getOverlappingReportId(overlappingRecordings: number[]) {
    for (const id of overlappingRecordings) {
      const reportId = this.getReportByRecording(id);
      if (reportId) return reportId;
    }
    return null;
  }

  @action
  toggleReports(reportId: number) {
    this.selectedReports.get(reportId)
      ? this.selectedReports.delete(reportId)
      : this.selectedReports.set(reportId, this.patientReports.get(reportId)!);
  }

  @action
  toggleUnreportedRecordings(recording: Study) {
    this.selectedUnreportedRecordings.get(recording.studyId)
      ? this.selectedUnreportedRecordings.delete(recording.studyId)
      : this.selectedUnreportedRecordings.set(recording.studyId, recording);
  }

  @action
  toggleReportedRecordings(recording: Study) {
    this.selectedReportedRecordings.get(recording.studyId)
      ? this.selectedReportedRecordings.delete(recording.studyId)
      : this.selectedReportedRecordings.set(recording.studyId, recording);
  }

  @action
  discardSelections() {
    this.selectedReportedRecordings.clear();
    this.selectedUnreportedRecordings.clear();
    this.selectedReports.clear();
    this.clearActiveStates();
  }

  @action
  clearActiveStates() {
    this.focusedRecordingIds = [];
    this.activeReportId = null;
  }

  @computed
  get getSelectedReportedRecordings() {
    return [...this.selectedReportedRecordings.values()];
  }

  @computed
  get getSelectedUnreportedRecordings() {
    return [...this.selectedUnreportedRecordings.values()];
  }

  @computed
  get getSelectedReports() {
    return [...this.selectedReports.values()];
  }

  @computed
  get getReports() {
    return [...this.patientReports.values()];
  }

  @computed
  get getUnreportedRecordings() {
    return [...this.unreportedRecordings.values()];
  }

  @action
  toggleRecordingsAccordion(reportId: number) {
    this.expandedRecordingsInReport.get(reportId)
      ? this.expandedRecordingsInReport.delete(reportId)
      : this.expandedRecordingsInReport.set(reportId, true);
  }

  isReportsRecordingsExpanded(reportId: number) {
    return this.expandedRecordingsInReport.get(reportId) || false;
  }
}
