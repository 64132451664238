import { custom, deserialize, serializable } from 'serializr';

import {
  excludeTimezoneOffset,
  getDateTimeFormat,
  parseDate
} from '../utils/dateHelpers';

export class StudyUpdateDTO {
  @serializable(
    custom(
      (sourcePropertyValue: Date) => sourcePropertyValue,
      (jsonValue: string) => {
        const format = getDateTimeFormat();
        const date = jsonValue ? parseDate(jsonValue, format) : null;

        return date && excludeTimezoneOffset(date);
      }
    )
  )
  latestMeal?: Date;

  @serializable
  internalStudyNotes?: string;

  @serializable
  studyNotes?: string;

  static deserialize(json: Object | string) {
    return deserialize(StudyUpdateDTO, json);
  }
}
