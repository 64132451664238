import { useEffect } from 'react';
import { StoreType } from 'enums/StoreType.enum';

import { useStore } from './store';

export const useStudyDetailsLoading = () => {
  const studyDetailsStore = useStore(StoreType.StudyDetails);
  const patientDetailsStore = useStore(StoreType.PatientDetails);
  const descriptionsStore = useStore(StoreType.Descriptions);
  const patientReportsStore = useStore(StoreType.PatientReports);
  const patientLockDetailsStore = useStore(StoreType.PatientLockDetails);
  const authStore = useStore(StoreType.Auth);

  const isStudyDetailsLoading =
    patientReportsStore.patientOverviewLoading ||
    studyDetailsStore.studyDetailsLoading ||
    patientDetailsStore.patientDetailsLoading ||
    descriptionsStore.descriptionDetailsLoading ||
    descriptionsStore.descriptionStatusLoading ||
    patientLockDetailsStore.patientLockLoading;

  useEffect(() => {
    if (!isStudyDetailsLoading && authStore.loadingStudyFromReader) {
      authStore.setLoadingStudyFromReaderStatus(false);
    }
  }, [isStudyDetailsLoading, authStore]);

  return isStudyDetailsLoading;
};
