import { Study } from 'entities/Study.entity';
import { EventTreeSettingsType } from 'enums/EventTreeSettingsType.enum';
import { action, makeObservable, observable } from 'mobx';
import { AsyncStorage } from 'services/AsyncStorage';

type EventTreeItemState = Map<Study['studyId'], Record<number, boolean>>;

export interface EventTreeSettingsConfig {
  categoriesState: EventTreeItemState;
  findingsState: EventTreeItemState;
  examplesState: EventTreeItemState;
}

export interface SettingsConfig {
  studyId: Study['studyId'];
  entityId: number | string;
  expanded: boolean;
  settingKey: EventTreeSettingsType;
}

export class EventTreeState {
  @observable
  categoriesState: EventTreeItemState = new Map();

  @observable
  findingsState: EventTreeItemState = new Map();

  @observable
  examplesState: EventTreeItemState = new Map();

  constructor() {
    makeObservable(this);
  }

  @action
  reset() {
    this.categoriesState = new Map();
    this.findingsState = new Map();
    this.examplesState = new Map();
  }

  @action
  async getEventTreeSettingsConfig() {
    try {
      const storageState = await AsyncStorage.getEventTreeSettingsConfig();

      this.categoriesState =
        observable.map(storageState?.categoriesState) || new Map();
      this.findingsState =
        observable.map(storageState?.findingsState) || new Map();
      this.examplesState =
        observable.map(storageState?.examplesState) || new Map();
    } catch (e) {}
  }

  @action
  async updateEventTreeSettingsConfig(settingsInfo: SettingsConfig) {
    const { studyId, entityId, expanded, settingKey } = settingsInfo;

    try {
      this[settingKey].set(studyId, {
        ...this[settingKey].get(studyId),
        [entityId]: expanded
      });

      await AsyncStorage.updateEventTreeSettingsConfig({
        categoriesState: this.categoriesState,
        findingsState: this.findingsState,
        examplesState: this.examplesState
      });
    } catch (e) {}
  }

  @observable
  getEventTreeEntityStateById(
    id: Study['studyId'],
    settingKey: EventTreeSettingsType
  ) {
    return this[settingKey].get(id);
  }
}
