import React, { useCallback, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  GeneralError,
  IconType,
  rebuildTooltips,
  TextBadge,
  Tooltip,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { Example } from 'components/Example';
import {
  ConfirmationTitle,
  useConfirmationContext
} from 'components/FindingsConfirmationModal';
import { useNameSelectionContext } from 'components/NameSelectionModal/NameSelectionContext';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { Event } from 'entities/Event.entity';
import { EventCoding } from 'entities/EventCoding.entity';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { shortcutsBaseTitles } from 'services/keyboardShortcuts/shortcutsBaseKeys';

import findingsStyles from '../Findings/Findings.module.scss';
import styles from './UnclassifiedExamplesContainer.module.scss';

export const BTN_COLLAPSE_EXAMPLES = 'btn-collapse-examples';

interface Props {
  data: Array<Event>;
  isLoading?: boolean;
  onCollapseUnclassifiedExamples: () => void;
  readOnly: boolean;
}

export const UnclassifiedExamplesContainer: React.FC<Props> = observer(
  ({ data, onCollapseUnclassifiedExamples, isLoading, readOnly }) => {
    const { id } = useParams<{ id: string }>();
    const { t } = useCustomTranslation();
    const {
      activeCodingId,
      onFindingClick,
      onCloseProperties
    } = usePropertiesContext();

    const hasActiveCoding = activeCodingId !== undefined;
    const lastCheckedExample = useRef<number>(-1);

    const findingsStore = useStore(StoreType.Findings);
    const reportsStore = useStore(StoreType.PatientReports);
    const reportDetails = reportsStore.patientReports.get(parseInt(id))!;
    const rtuStore = useStore(StoreType.RealTimeUpdates);

    const activeDescriptionId = parseInt(id);
    const nameSelectionContext = useNameSelectionContext();
    const confirmationContext = useConfirmationContext();

    const [{ isOver }, drop] = useDrop(() => ({
      accept: [DraggableTypes.example, DraggableTypes.finding],
      drop: async (item: any, monitor) => {
        if (monitor.didDrop()) {
          return;
        }
        if (
          monitor.getItemType() === DraggableTypes.example &&
          item.eventCodingId !== null
        ) {
          await findingsStore.deleteEvents(
            activeDescriptionId,
            findingsStore.selectionState.draggedExample
              ? [findingsStore.selectionState.draggedExample.eventId]
              : findingsStore.selectionState.selectedExamples.map(
                  (example) => example.eventId
                )
          );
        } else if (monitor.getItemType() === DraggableTypes.finding) {
          await findingsStore.deleteEventCodings(
            activeDescriptionId,
            findingsStore.selectionState.draggedFinding
              ? [
                  findingsStore.selectionState.draggedFinding?.item
                    .eventCodingId
                ]
              : findingsStore.selectionState.selectedFindingsIds
          );
        }
        findingsStore.selectionState.discardSelections();
      },
      collect: (monitor) => ({
        isOver: monitor.isOver()
      })
    }));

    useEffect(() => {
      rebuildTooltips();
    }, []);

    const onDoubleClick = (studyId: number, exampleId: Event['eventId']) => {
      rtuStore.activateEvent(studyId, activeDescriptionId, exampleId);
    };

    const createEventCoding = useCallback(
      async (eventCodeId: number, exampleId: Event['eventId']) => {
        const eventCodings: EventCoding[] = await findingsStore.createEventCoding(
          {
            studyId: activeDescriptionId,
            data: {
              eventIds: [exampleId],
              eventCodeId
            },
            descriptionId: activeDescriptionId,
            identifier: `example-classify-${exampleId}`
          }
        );

        const activeEventCoding = eventCodings.find(
          (eventCoding) => eventCoding.eventCodeId === eventCodeId
        );

        !!eventCodings.length &&
          !findingsStore.isToBeDefined(eventCodeId) &&
          onFindingClick(activeEventCoding);
      },
      [activeDescriptionId, findingsStore, onFindingClick]
    );

    const onClassify = useCallback(
      (exampleId: Event['eventId']) => {
        nameSelectionContext?.onOpen({
          title: t('Classify'),
          onSelect: (eventCodeId) => {
            if (
              findingsStore.hasActiveOnlyOneEventCoding(
                activeDescriptionId,
                eventCodeId
              )
            ) {
              confirmationContext?.onOpen({
                title: t(ConfirmationTitle.Add_Second_OnlyOneEventCoding),
                submitButtonTitle: t('Yes, sure'),
                onSubmit: () => createEventCoding(eventCodeId, exampleId)
              });
            } else {
              createEventCoding(eventCodeId, exampleId);
            }

            nameSelectionContext.onClose();
          }
        });
      },
      [
        activeDescriptionId,
        confirmationContext,
        createEventCoding,
        findingsStore,
        nameSelectionContext,
        t
      ]
    );

    const formattedDateOfEvent = useCallback(
      (event: Event) => {
        return event.formatStartDateTime(
          reportDetails.reportStartDateTime,
          reportDetails.reportStopDateTime
        );
      },
      [reportDetails.reportStartDateTime, reportDetails.reportStopDateTime]
    );

    return (
      <div
        className={cn(
          styles['unclassified-examples-container'],
          isOver ? findingsStyles['on-dropover'] : ''
        )}
        ref={drop}
        data-testid='unclassify-droppable-area'
      >
        <div className={styles.header}>
          <h5 className={styles['title']}>{t('Unclassified examples')}</h5>
          <Tooltip
            data={[
              {
                mainTooltip: (
                  <span>
                    {t('Collapse')}
                    <TextBadge title={shortcutsBaseTitles.CMD} />
                    +
                    <TextBadge title={t(';')} />
                  </span>
                )
              }
            ]}
          >
            <Button
              data-testid={BTN_COLLAPSE_EXAMPLES}
              onClick={onCollapseUnclassifiedExamples}
              size={ButtonSize.Small}
              icon={IconType.SkipArrow}
              theme={ButtonTheme.SecondaryTransparent}
              className={styles['btn-collapse']}
            />
          </Tooltip>
        </div>

        <div className={styles['list-wrap']}>
          {!isLoading && (
            <div
              className={cn([
                styles.list,
                data?.length < 1 && styles['list-stretch-height']
              ])}
            >
              {data?.length ? (
                data.map((item, index) => {
                  const exampleState = findingsStore.selectionState.getExampleState(
                    item
                  );

                  return (
                    <Example
                      readOnly={readOnly}
                      onDoubleClick={onDoubleClick}
                      key={item.eventId}
                      index={index}
                      eventDetails={item}
                      onSelect={(e) => {
                        if (hasActiveCoding) {
                          onCloseProperties();
                        }
                        if (
                          e.nativeEvent.shiftKey &&
                          lastCheckedExample.current !== -1
                        ) {
                          const selectedExamples = data.slice(
                            Math.min(index, lastCheckedExample.current),
                            Math.max(index, lastCheckedExample.current) + 1
                          );
                          findingsStore.selectionState.selectMultipleExamples(
                            selectedExamples
                          );
                        } else {
                          findingsStore.selectionState.toggleExample(item);
                        }
                        lastCheckedExample.current = index;
                      }}
                      onClassify={onClassify}
                      className={styles['list-item']}
                      isActive={
                        rtuStore.realTimeUpdatesConfig.activeEventId ===
                        item.eventId
                      }
                      eventDate={formattedDateOfEvent(item) || ''}
                      isSelected={exampleState.selected}
                      isCheckboxDisabled={exampleState.checkboxDisabled}
                      isActionsAvailable={exampleState.actionsAvailable}
                    />
                  );
                })
              ) : (
                <div className={styles['empty-list']}>
                  <div className={styles['empty-list-container']}>
                    <GeneralError
                      className={styles['general-error']}
                      icon={IconType.Empty}
                      title={t('This list looks empty')}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
