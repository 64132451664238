import {
  BaseBlock,
  ReportDataFacade,
  StatusIndicatorStatus
} from '@holberg/ui-kit/dist';
import { ApiError } from 'entities/ApiError.entity';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { Description } from 'entities/Description.entity';
import { DescriptionUpdateDTO } from 'entities/DescriptionUpdateDTO.entity';
import { Study } from 'entities/Study.entity';
import { StudyPropertyCodingCreateDTO } from 'entities/StudyPropertyCodingCreateDTO.entity';
import { StudyPropertyCodingUpdateDTO } from 'entities/StudyPropertyCodingUpdateDTO.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { DescriptionPropertyTypes } from 'enums/DescriptionPropertyType.enum';
import { PropertyCodeTagType } from 'enums/PropertyCodeTagType.enum';
import { Routes } from 'enums/Routes.enum';
// import { RealTimeUpdateSendMessages } from 'enums/RealTimeUpdateType.enum';
import { SaveOperationType } from 'enums/SaveOperationType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { action, computed, makeObservable, observable } from 'mobx';
import { ApiLoadingState } from 'services/API/ApiLoadingState';
import { ApiRetryManager } from 'services/API/ApiRetryManager';
import { StudyApi } from 'services/API/Study/StudyApi';
// import { RTUManager } from 'services/RealTimeUpdatesManager';
import { BaseStore } from 'stores/BaseStore';
import { stores } from 'stores/index';

import { CategoricalPropertyCodingState } from '../categorical-property-coding/CategoricalPropertyCodingState';
import { CommonPropertyType } from '../property-type-codes';

const conclusionRequiredPropertyTypes = Object.freeze([
  DescriptionPropertyTypes.DiagnosticSignificance
]);

const studyDetailsRequiredPropertyTypes = Object.freeze([
  DescriptionPropertyTypes.IndicationForEEG
]);

export class DescriptionsStore implements BaseStore {
  readonly categoricalPropertyCodingState = new CategoricalPropertyCodingState();

  @observable
  descriptionDetails: Map<
    number,
    ReturnType<typeof Description.deserialize>
  > = new Map();

  @observable
  descriptionDetailsLoading: boolean = false;

  @observable
  descriptionDetailsError?: ApiError | UnknownError;

  @observable
  descriptionStatusLoading: boolean = false;

  @observable
  descriptionStatusError?: ApiError | UnknownError;

  @observable
  descriptions: Map<
    Study['studyId'],
    ReturnType<typeof Description.deserializeAsList>
  > = new Map();
  @observable descriptionsLoading: boolean = false;
  @observable descriptionsError?: ApiError | UnknownError;

  @observable
  readonly retryManager: ApiRetryManager = new ApiRetryManager();

  @observable
  readonly apiLoadingState: ApiLoadingState = new ApiLoadingState();

  constructor() {
    makeObservable(this);
    this.reset();
  }

  hasCodeWithRequiredTag({
    descriptionId,
    propertyTypeId,
    requiredTagType
  }: {
    descriptionId: Description['descriptionId'];
    propertyTypeId: CommonPropertyType;
    requiredTagType: PropertyCodeTagType;
  }) {
    const tagsList = stores[
      StoreType.DescriptionPropertyTypeCodes
    ].getPropertyTypeCodesTags(propertyTypeId);

    return !!(this.propertyCodings(descriptionId)[propertyTypeId] || []).find(
      (entity) => {
        const propertyCodeTags = tagsList.get(
          entity.propertyCodeModel.propertyCodeId
        );

        return propertyCodeTags ? propertyCodeTags.get(requiredTagType) : false;
      }
    );
  }

  propertyCodings(
    id: Description['descriptionId']
  ): ReturnType<typeof CategoricalPropertyCoding.deserializeAsMap> {
    return this.categoricalPropertyCodingState.categoricalPropertyCodingsById(
      String(id)
    );
  }

  descriptionById(
    descriptionId?: Description['descriptionId']
  ): Description | undefined {
    return descriptionId
      ? this.descriptionDetails.get(descriptionId)
      : undefined;
  }

  descriptionsByStudyId(id: string): Description[] {
    return this.descriptions.get(parseInt(id)) || [];
  }

  isReadOnly(id: Description['descriptionId']): boolean {
    return this.descriptionById(id)?.isCompleted || false;
  }

  @action reset() {
    this.descriptions = new Map();
    this.descriptionsLoading = false;
    this.descriptionsError = undefined;
    this.descriptionDetails = new Map();
    this.descriptionDetailsLoading = false;
    this.descriptionDetailsError = undefined;
    this.descriptionStatusError = undefined;
    this.categoricalPropertyCodingState.reset();
    this.apiLoadingState.reset();
    this.retryManager.stopAll();
  }

  @computed
  get hasGiveUpSaving(): boolean {
    return this.retryManager.hasGiveUp;
  }

  @computed
  get saveStatus(): StatusIndicatorStatus {
    if (this.apiLoadingState.isAnyLoading || this.retryManager.isRetrying) {
      return StatusIndicatorStatus.Loading;
    }

    if (this.retryManager.errors.length) {
      return StatusIndicatorStatus.Error;
    }

    return StatusIndicatorStatus.Saved;
  }

  @action
  retryAllRequests() {
    this.retryManager.retryAll();
  }

  @action
  clearAllRequests() {
    this.apiLoadingState.reset();
    this.retryManager.stopAll();
  }

  hasRequiredData(id: Description['descriptionId'], tabRoute: string): boolean {
    const propertyCodings = this.propertyCodings(id);
    if (tabRoute === Routes.StudyDetails) {
      return studyDetailsRequiredPropertyTypes.every(
        (propertyType) => propertyCodings[propertyType]?.length
      );
    } else if (tabRoute === Routes.StudyConclusion) {
      return conclusionRequiredPropertyTypes.every(
        (propertyType) => propertyCodings[propertyType]?.length
      );
    }
    return false;
  }

  @action
  async loadDescriptionDetails(descriptionId: Description['descriptionId']) {
    this.descriptionDetailsLoading = true;
    this.descriptionDetailsError = undefined;
    try {
      const { data } = await StudyApi.loadDescriptionDetails(descriptionId);

      this.categoricalPropertyCodingState.addCategoricalPropertyCodings(
        descriptionId,
        data.propertyCodings
      );

      this.descriptionDetails.set(descriptionId, Description.deserialize(data));
    } catch (e) {
      this.descriptionDetailsError = ApiError.deserializeFromCatch(e);
    } finally {
      this.descriptionDetailsLoading = false;
    }
  }

  @action
  addDescriptionDetailsData(
    descriptionId: Description['descriptionId'],
    data: Description
  ) {
    this.descriptionDetailsLoading = true;
    this.descriptionDetailsError = undefined;
    try {
      this.categoricalPropertyCodingState.addCategoricalPropertyCodings(
        descriptionId,
        data.propertyCodings
      );
      this.descriptionDetails.set(descriptionId, Description.deserialize(data));
    } catch (e) {
      this.descriptionDetailsError = ApiError.deserializeFromCatch(e);
    } finally {
      this.descriptionDetailsLoading = false;
    }
  }

  @action
  async loadDescriptionsByStudyId(studyId: Study['studyId']) {
    this.descriptionsLoading = true;
    this.descriptionsError = undefined;
    try {
      const { data } = await StudyApi.loadDescriptionsListByStudyId(studyId);

      this.descriptions.set(studyId, Description.deserializeAsList(data));
    } catch (e) {
      this.descriptionsError = ApiError.deserializeFromCatch(e);
    } finally {
      this.descriptionsLoading = false;
    }
  }

  @action
  async updateDescriptionDetails(
    value: string | null,
    descriptionId: Description['descriptionId'],
    propertyName: string
  ) {
    const saveIdentifier = `${SaveOperationType.SAVE}_${descriptionId}_${propertyName}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const updateDto = DescriptionUpdateDTO.deserialize({
      [propertyName]: value
    });

    try {
      const { data } = await StudyApi.updateDescriptionDetails(
        descriptionId,
        updateDto
      );

      const updateDescription = Description.deserialize(data);

      this.descriptionDetails.set(
        updateDescription.descriptionId,
        Description.deserialize(updateDescription)
      );

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = StudyApi.createRetryState<Description>(e);

      retryState?.addEventListener('success', () => {
        if (retryState.result) {
          const { data } = retryState.result;
          const updateDescription = Description.deserialize(data);
          this.descriptionDetails.set(
            updateDescription.descriptionId,
            Description.deserialize(updateDescription)
          );
        }

        stores[StoreType.Messages].removeError(saveIdentifier);
      });

      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );
      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async createPropertyCodings(
    propertyCodeId: string,
    propertyTypeId: CommonPropertyType,
    descriptionId: Description['descriptionId']
  ) {
    const saveIdentifier = `${SaveOperationType.SAVE}_${descriptionId}_${propertyTypeId}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const createDto = StudyPropertyCodingCreateDTO.deserialize({
      propertyCodeId: parseInt(propertyCodeId),
      propertyTypeId
    });

    try {
      const { data } = await StudyApi.createDescriptionPropertyCoding(
        descriptionId,
        createDto
      );

      this.categoricalPropertyCodingState.addCategoricalPropertyCoding({
        entityId: descriptionId,
        propertyTypeId,
        data: CategoricalPropertyCoding.deserialize(data)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = StudyApi.createRetryState<CategoricalPropertyCoding>(
        e
      );

      retryState?.addEventListener('success', () => {
        if (retryState.result) {
          this.categoricalPropertyCodingState.addCategoricalPropertyCoding({
            entityId: descriptionId,
            propertyTypeId,
            data: CategoricalPropertyCoding.deserialize(retryState.result)
          });
        }

        stores[StoreType.Messages].removeError(saveIdentifier);
      });

      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async updatePropertyCodings({
    newPropertyCodeId,
    propertyTypeId,
    oldPropertyCodeId,
    descriptionId
  }: {
    newPropertyCodeId?: string;
    propertyTypeId: CommonPropertyType;
    oldPropertyCodeId?: string;
    descriptionId: Description['descriptionId'];
  }) {
    if (!oldPropertyCodeId && !!newPropertyCodeId) {
      this.createPropertyCodings(
        newPropertyCodeId,
        propertyTypeId,
        descriptionId
      );
      return;
    }

    if (!!oldPropertyCodeId && !newPropertyCodeId) {
      this.deletePropertyCodings(
        descriptionId,
        oldPropertyCodeId,
        propertyTypeId
      );
      return;
    }

    if (!(oldPropertyCodeId && newPropertyCodeId)) {
      return;
    }

    const saveIdentifier = `${SaveOperationType.UPDATE}_${descriptionId}_${propertyTypeId}`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const updateDto = StudyPropertyCodingUpdateDTO.deserialize({
      propertyCodeId: parseInt(newPropertyCodeId)
    });

    try {
      const { data } = await StudyApi.updateDescriptionPropertyCoding({
        dto: updateDto,
        propertyTypeId,
        currentPropertyCodeId: oldPropertyCodeId,
        descriptionId
      });

      this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
        entityId: descriptionId,
        propertyTypeId,
        propertyCodeId: parseInt(oldPropertyCodeId),
        data: CategoricalPropertyCoding.deserialize(data)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = StudyApi.createRetryState<CategoricalPropertyCoding>(
        e
      );

      retryState?.addEventListener('success', () => {
        stores[StoreType.Messages].removeError(saveIdentifier);

        if (retryState.result) {
          this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
            entityId: descriptionId,
            propertyTypeId,
            propertyCodeId: parseInt(oldPropertyCodeId),
            data: CategoricalPropertyCoding.deserialize(retryState.result)
          });
        }
      });

      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async deletePropertyCodings(
    descriptionId: Description['descriptionId'],
    propertyCodeId: string,
    propertyTypeId: CommonPropertyType
  ) {
    const saveIdentifier = `${SaveOperationType.DELETE}_${descriptionId}_${propertyTypeId}`;
    const propertyCoding = {
      entityId: descriptionId,
      propertyTypeId,
      propertyCodeId: parseInt(propertyCodeId)
    };

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    try {
      await StudyApi.deleteDescriptionPropertyCoding({
        descriptionId,
        propertyTypeId,
        propertyCodeId
      });

      this.categoricalPropertyCodingState.deleteCategoricalPropertyCoding(
        propertyCoding
      );

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = StudyApi.createRetryState(e);

      retryState?.addEventListener('success', () => {
        stores[StoreType.Messages].removeError(saveIdentifier);

        this.categoricalPropertyCodingState.deleteCategoricalPropertyCoding(
          propertyCoding
        );
      });

      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async updatePropertyCodingsNote({
    currentPropertyCodeId,
    propertyTypeId,
    descriptionId,
    freeText
  }: {
    currentPropertyCodeId: string;
    propertyTypeId: CommonPropertyType;
    freeText: string;
    descriptionId: Description['descriptionId'];
  }) {
    const saveIdentifier = `${SaveOperationType.UPDATE}_${descriptionId}_${propertyTypeId}_freeText`;

    this.retryManager.cancelRetryFor(saveIdentifier);
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);

    const updateDto = StudyPropertyCodingUpdateDTO.deserialize({ freeText });

    try {
      const { data } = await StudyApi.updateDescriptionPropertyCoding({
        dto: updateDto,
        propertyTypeId,
        currentPropertyCodeId,
        descriptionId
      });

      this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
        entityId: descriptionId,
        propertyTypeId,
        propertyCodeId: parseInt(currentPropertyCodeId),
        data: CategoricalPropertyCoding.deserialize(data)
      });

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      const retryState = StudyApi.createRetryState<CategoricalPropertyCoding>(
        e
      );
      retryState?.addEventListener('success', () => {
        stores[StoreType.Messages].removeError(saveIdentifier);

        if (retryState.result) {
          this.categoricalPropertyCodingState.updateCategoricalPropertyCoding({
            entityId: descriptionId,
            propertyTypeId,
            propertyCodeId: parseInt(currentPropertyCodeId),
            data: CategoricalPropertyCoding.deserialize(retryState.result)
          });
        }
      });

      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );

      if (retryState) {
        this.retryManager.addRetryState(saveIdentifier, retryState);
      }
    } finally {
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
      this.retryManager.retryAll();
    }
  }

  @action
  async completeDescription(
    descriptionId: Description['descriptionId'],
    payload: {
      data: ReportDataFacade;
      schema: BaseBlock;
    },
    onSuccess: () => void = () => {}
  ) {
    const saveIdentifier = 'complete-description';
    this.descriptionStatusLoading = true;
    this.descriptionStatusError = undefined;

    try {
      const { data } = await StudyApi.completeDescription(
        descriptionId,
        payload
      );

      this.descriptionDetails.set(descriptionId, Description.deserialize(data));

      stores[StoreType.Messages].removeError(saveIdentifier);

      onSuccess();
    } catch (e) {
      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );
      this.descriptionStatusError = ApiError.deserializeFromCatch(e);
    } finally {
      this.descriptionStatusLoading = false;
    }
  }

  @action
  async reopenDescription(descriptionId: Description['descriptionId']) {
    const saveIdentifier = 'reopen-description';
    this.descriptionStatusLoading = true;
    this.descriptionStatusError = undefined;

    try {
      const { data } = await StudyApi.reopenDescription(descriptionId);
      // RTUManager.sendMessage(RealTimeUpdateSendMessages.RequestStudyEditLock, {
      //   studyId
      // });
      this.descriptionDetails.set(descriptionId, Description.deserialize(data));

      stores[StoreType.Messages].removeError(saveIdentifier);
    } catch (e) {
      stores[StoreType.Messages].addMsgError(
        saveIdentifier,
        ApiError.deserializeFromCatch(e)
      );
      this.descriptionStatusError = ApiError.deserializeFromCatch(e);
    } finally {
      this.descriptionStatusLoading = false;
    }
  }
}
