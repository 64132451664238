import isSameDay from 'date-fns/isSameDay';
import { action, makeObservable } from 'mobx';
import { date, deserialize, serializable } from 'serializr';
import {
  formatLocaleDateTime,
  formatLocaleDayNumber,
  formatLocaleTime
} from 'utils/dateHelpers';

export class Event {
  @serializable
  eventId: number = 0;

  @serializable
  eventCodingId: number = 0;

  @serializable
  eventTypeMappingId: number = 0;

  @serializable
  isActive: boolean = false;

  @serializable
  recordingId?: number;

  @serializable
  studyId: number = 0;

  @serializable
  externalEventId: string = '';

  @serializable(date())
  startDatetime: Date = new Date();

  @serializable(date())
  endDatetime: Date = new Date();

  @serializable
  freeText?: string;

  constructor() {
    makeObservable(this);
  }

  @action
  formatStartDateTime(
    firstCompareDate?: string | number,
    secondCompareDate?: string | number
  ): string | undefined {
    if (firstCompareDate && secondCompareDate) {
      try {
        return isSameDay(
          new Date(firstCompareDate),
          new Date(secondCompareDate)
        )
          ? formatLocaleTime(this.startDatetime, '')
          : formatLocaleDayNumber(
              new Date(firstCompareDate),
              this.startDatetime,
              ''
            );
      } catch (e) {
        return formatLocaleDateTime(this.startDatetime, '');
      }
    }

    return formatLocaleDateTime(this.startDatetime, '');
  }

  static deserialize(json: Object | string) {
    return deserialize(Event, json);
  }

  static deserializeAsList(list: Event[]): Event[] {
    return list.map(Event.deserialize);
  }

  static deserializeAsMap(list: Event[]): Map<Event['eventId'], Event> {
    return list.reduce((acc, eventItem) => {
      acc.set(eventItem.eventId, Event.deserialize(eventItem));

      return acc;
    }, new Map());
  }
}
