import React from 'react';
import { useParams } from 'react-router-dom';
import {
  defaultNeonatalTemplate,
  defaultTemplate,
  ErrorTheme,
  ErrorTitles,
  GeneralError,
  ReportDataContainer,
  SchemaRenderer
} from '@holberg/ui-kit';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

import { useReportData } from './useReportData';

import styles from './ReportSection.module.scss';

export const REPORT_SECTION = 'report-section';

export const ReportSection: React.FC = observer(() => {
  const patientDetailsStore = useStore(StoreType.PatientDetails);
  const descriptionsStore = useStore(StoreType.Descriptions);
  const findingsStore = useStore(StoreType.Findings);

  const { id } = useParams<{ id: string }>();
  const descriptionId = parseInt(id);
  const descriptionDetails = descriptionsStore.descriptionById(descriptionId);
  const reportData = useReportData(descriptionId);

  const isError =
    patientDetailsStore.categoricalPropertyCodingsError ||
    findingsStore.reportHeadModelError ||
    descriptionsStore.descriptionDetailsError;

  return (
    <section className={styles['report-section']} data-testid={REPORT_SECTION}>
      {isError ? (
        <div className={styles['error-container']}>
          <GeneralError
            theme={ErrorTheme.Secondary}
            title={ErrorTitles.Load}
            className={styles['error']}
          />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.content}>
            <ReportDataContainer data={reportData}>
              <SchemaRenderer
                schema={
                  descriptionDetails?.isNeonatal
                    ? defaultNeonatalTemplate
                    : defaultTemplate
                }
              />
            </ReportDataContainer>
          </div>
        </div>
      )}
    </section>
  );
});
