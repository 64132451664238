import React, { FC, useEffect } from 'react';
import { ControllerRenderProps } from 'react-hook-form/dist/index.ie11';
import { FormInput, useCustomTranslation } from '@holberg/ui-kit';
import { useHasFocus } from 'hooks/useHasFocus';
import { NumberPropertyCoding } from 'stores/finding-properties';

import styles from './PropertyPanelInput.module.scss';

interface Props {
  field: ControllerRenderProps<NumberPropertyCoding>;
  value1Focus: ReturnType<typeof useHasFocus>;
  value2Focus: ReturnType<typeof useHasFocus>;
  hasRange?: boolean;
  disabled?: boolean;
  error?: string;
  onBlur: () => void;
}

export const PropertyFormInput: FC<Props> = ({
  field,
  value1Focus,
  value2Focus,
  hasRange,
  disabled,
  error,
  onBlur
}) => {
  const { t } = useCustomTranslation();

  const isInputBlurred = !value1Focus.hasFocus && !value2Focus.hasFocus;

  useEffect(() => {
    if (disabled) {
      field.onChange({ ...field.value, value1: null, value2: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <>
      <div className={styles['input-wrapper']}>
        <FormInput
          value={!disabled ? field.value?.value1 || '' : ''}
          onBlur={() => {
            value1Focus.onBlur();
            field.onBlur();
            onBlur();
          }}
          onFocus={value1Focus.onFocus}
          onChange={(e) =>
            field.onChange({ ...field.value, value1: e.target.value || null })
          }
          error={
            error && !field.value?.value1 && isInputBlurred
              ? t(error)
              : undefined
          }
          placeholder={t('n')}
          type='number'
          disabled={disabled}
          data-testid='number-input'
        />
      </div>

      {hasRange && (
        <div className={styles['input-wrapper']}>
          <FormInput
            value={!disabled ? field.value?.value2 || '' : ''}
            onBlur={() => {
              value2Focus.onBlur();
              field.onBlur();
              onBlur();
            }}
            onFocus={value2Focus.onFocus}
            onChange={(e) =>
              field.onChange({ ...field.value, value2: e.target.value || null })
            }
            error={
              error && !field.value?.value2 && isInputBlurred
                ? t(error)
                : undefined
            }
            placeholder={t('n')}
            type='number'
            disabled={disabled}
            data-testid='number-input'
          />
        </div>
      )}
    </>
  );
};
