import { computed, makeObservable } from 'mobx';
import { deserialize, list, object, primitive, serializable } from 'serializr';
import {
  dateDifference,
  formatLocaleDate,
  formatLocaleDateTime
} from 'utils/dateHelpers';

import { Age } from './Age.entity';
import { CategoricalPropertyCoding } from './CategoricalPropertyCoding.entity';
import { InformationModel } from './InformationModel.entity';

export class Study {
  @serializable
  studyId: number = 0;

  @serializable
  externalStudyId: string | null = null;

  @serializable
  activeDescriptionId: number = 0;

  @serializable
  patientId: number = 0;

  @serializable(object(InformationModel))
  studyStart: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  studyStop: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  latestMeal: InformationModel = new InformationModel();

  @serializable(list(primitive()))
  overlappingRecordingIds: number[] = [];

  @serializable
  isArchived?: boolean;

  @serializable
  isDeleted?: boolean;

  @serializable
  isOnline?: boolean;

  @serializable(object(Age))
  ageAtStudy: Age = new Age();

  @serializable(object(Age))
  correctedAge: Age = new Age();

  @serializable(object(Age))
  postmenstrualAgeAtStudy: Age = new Age();

  @serializable(object(InformationModel))
  studyLength: InformationModel = new InformationModel();

  @serializable(object(InformationModel))
  totalRecordingTime: InformationModel = new InformationModel();

  @serializable
  ageConstraints: string = 'all';

  @serializable
  isStudyTimeEditedByUser: boolean = false;

  @serializable
  miniHeadModel: string = '';

  @serializable(list(object(CategoricalPropertyCoding)))
  propertyCodings: CategoricalPropertyCoding[] = [];

  @serializable
  studyNotes: string = '';

  constructor() {
    makeObservable(this);
  }

  private getDateFromInformationModel(informationModel?: InformationModel) {
    return informationModel?.secondaryValue
      ? new Date(informationModel.secondaryValue)
      : undefined;
  }

  get formattedLatestMeal() {
    const date = this.getDateFromInformationModel(this.latestMeal);

    return formatLocaleDateTime(date, '', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  @computed
  get totalTime() {
    return dateDifference(
      new Date(this.studyStop.eitherValue),
      new Date(this.studyStart.eitherValue),
      true
    );
  }

  @computed
  get ageAtStudyTime() {
    return this.ageAtStudy.displayText.eitherValue || '-';
  }

  @computed
  get isNeonatal() {
    const maxAgeInlMonths = 12;

    return (
      Number.isInteger(this.ageAtStudy.ageInMonths) &&
      this.ageAtStudy.ageInMonths <= maxAgeInlMonths
    );
  }

  get formattedStudyStart(): string {
    const date = this.getDateFromInformationModel(this.studyStart);

    return formatLocaleDate(date, '') as string;
  }

  get formattedStudyStartDateTime(): string {
    const date = this.getDateFromInformationModel(this.studyStart);

    return formatLocaleDateTime(date, '', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }) as string;
  }

  get formattedStudyStop(): string {
    const date = this.getDateFromInformationModel(this.studyStop);

    return formatLocaleDate(date, '') as string;
  }

  get formattedStudyStopDateTime(): string {
    const date = this.getDateFromInformationModel(this.studyStop);

    return formatLocaleDateTime(date, '', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }) as string;
  }

  @computed
  get eegType() {
    const eegPropertyCoding = this.propertyCodings.find(
      ({ propertyTypeModel }) => propertyTypeModel?.name === 'EEGType'
    );

    if (eegPropertyCoding) {
      return eegPropertyCoding.propertyCodeModel.translatedName.eitherValue;
    }

    return '';
  }

  static deserialize(json: Object | string) {
    return deserialize(Study, json);
  }

  static deserializeAsList(list: Study[]): Study[] {
    return list.map(Study.deserialize);
  }
}
