import { Description } from 'entities/Description.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { Report } from 'entities/Report.entity';

import { ApiBase } from '../ApiBase';

class ReportScopeApi extends ApiBase {
  loadPatientReports(patientId: PatientDetails['patientId']) {
    return this.client.get<Report[]>(`/reportScopes?patientId=${patientId}`);
  }

  moveRecordings(
    patientId: PatientDetails['patientId'],
    recordingIds: number[],
    descriptionId?: Description['descriptionId']
  ) {
    const query = descriptionId
      ? `descriptionId=${descriptionId}&patientId=${patientId}&recordingIds=${recordingIds.join(
          ','
        )}`
      : `patientId=${patientId}&recordingIds=${recordingIds.join(',')}`;
    return this.client.put<Report[]>(`/reportScopes?${query}`);
  }

  getReportsById(descriptionIds: number[]) {
    return this.client.get<Report[]>(
      `/reportScopes/descriptions/${descriptionIds.join(',')}`
    );
  }
}

const instance = new ReportScopeApi('/api/v1');

export { instance as ReportScopeApi };
