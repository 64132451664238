import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import {
  FormInput,
  InputContainer,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { Description } from 'entities/Description.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

import { GestationalAgeRow } from '../GestationalAgeRow';

import styles from './NeonatalInfoForm.module.scss';

interface Props {
  onPropertyBlur?: (accessor: string, propertyName: string) => void;
  patientId?: string;
  descriptionId?: number;
  readOnly?: boolean;
}

export const NeonatalInfoForm: React.FC<Props> = observer(
  ({
    onPropertyBlur = () => {},
    patientId,
    descriptionId,
    readOnly = false
  }) => {
    const { t } = useCustomTranslation();

    let descriptionDetails: Description | undefined;
    let patientDetails: PatientDetails | undefined;
    const reportsStore = useStore(StoreType.PatientReports);
    const patientDetailsStore = useStore(StoreType.PatientDetails);
    const isFirstRenderDone = useRef(false);

    if (descriptionId) {
      const reportDetails = reportsStore.patientReports.get(descriptionId)!;
      descriptionDetails = reportDetails.description!;
      patientDetails = patientDetailsStore.patientById(
        descriptionDetails!.patientId.toString()
      );
    }
    if (patientId) {
      patientDetails = patientDetailsStore.patientById(patientId);
    }

    const { setValue } = useFormContext<{
      patientDetails: PatientDetails;
    }>();

    useEffect(() => {
      if (patientId) {
        setValue(
          'patientDetails.formattedCorrectedAge',
          patientDetails?.formattedCorrectedAge
        );
        setValue(
          'patientDetails.formattedPostMenstrualAge',
          patientDetails?.formattedPostMenstrualAge
        );
      }
    }, [
      patientDetails?.formattedCorrectedAge,
      patientDetails?.formattedPostMenstrualAge,
      patientId,
      setValue
    ]);

    useEffect(() => {
      if (descriptionId && isFirstRenderDone.current) {
        reportsStore.getReportsByIds([descriptionId]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      patientDetails?.formattedCorrectedAge,
      patientDetails?.formattedPostMenstrualAge
    ]);

    useEffect(() => {
      isFirstRenderDone.current = true;
    }, []);

    return (
      <>
        <InputContainer label={t('Gestational age')}>
          <div
            data-testid='unit-row'
            className={cn(styles['unit-row'], readOnly && styles['read-only'])}
          >
            <GestationalAgeRow
              readOnly={readOnly}
              onPropertyBlur={onPropertyBlur}
            />
          </div>
        </InputContainer>
        <InputContainer label={t('Corrected age')}>
          <div className={styles['input-container']}>
            <FormInput
              readOnly
              value={
                descriptionId
                  ? descriptionDetails!.correctedAgeAtStudyTime
                  : patientDetails!.formattedCorrectedAge
              }
            />
          </div>
        </InputContainer>
        <InputContainer label={t('Postmenstrual age')}>
          <div className={styles['input-container']}>
            <FormInput
              readOnly
              value={
                descriptionId
                  ? descriptionDetails!.postMenstrualAgeAtStudyTime
                  : patientDetails!.formattedPostMenstrualAge
              }
            />
          </div>
        </InputContainer>
      </>
    );
  }
);
