import { deserialize, serializable } from 'serializr';

export class DescriptionUpdateDTO {
  @serializable
  clinicalComment?: string;

  @serializable
  eegSummary?: string;

  static deserialize(json: Object | string) {
    return deserialize(DescriptionUpdateDTO, json);
  }
}
