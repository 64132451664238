import React, { useCallback, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import {
  Button,
  ButtonTheme,
  Checkbox,
  IconType,
  rebuildTooltips,
  Tooltip,
  useCustomTranslation
} from '@holberg/ui-kit';
import classnames from 'classnames';
import { Event } from 'entities/Event.entity';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';

import styles from './Example.module.scss';

export const EXAMPLE_BTN_CLASSIFY = 'example-btn-classify';
export const EXAMPLE_BTN_DELETE = 'example-btn-delete';

interface Props {
  eventDetails: Event;
  eventDate: string;
  isActive?: boolean;
  isSelected?: boolean;
  isCheckboxHidden?: boolean;
  isCheckboxDisabled?: boolean;
  isActionsAvailable?: boolean;
  onSelect: (e: any, id: Event['eventId']) => void;
  onDoubleClick: (studyId: number, id: Event['eventId']) => void;
  onClassify: (id: Event['eventId']) => void;
  onDelete?: (id: Event['eventId']) => void;
  horizontalAlignment?: boolean;
  className?: string;
  index: number;
  readOnly: boolean;
}

export const Example: React.FC<Props> = ({
  eventDetails,
  eventDate,
  onSelect,
  onClassify,
  onDelete,
  onDoubleClick,
  isSelected = false,
  isActive = false,
  isCheckboxHidden = false,
  isCheckboxDisabled = false,
  horizontalAlignment = false,
  isActionsAvailable = true,
  className,
  readOnly
}) => {
  const { t } = useCustomTranslation();
  const activeExampleRef = useRef<HTMLDivElement>(null);
  const findingsStore = useStore(StoreType.Findings);

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: DraggableTypes.example,
      canDrag: () =>
        !findingsStore.selectionState.selectedFindings.length &&
        (findingsStore.selectionState.selectedExamples.length
          ? !readOnly &&
            findingsStore.selectionState.selectedExamples.some(
              (example) => example.eventId === eventDetails.eventId
            )
          : !readOnly),
      end: (_item, monitor) => {
        if (!monitor.didDrop()) {
          findingsStore.selectionState.discardExamplesSelections();
        }
      },
      item: {
        id: eventDetails.eventId,
        eventDate: eventDate,
        eventCodingId: eventDetails.eventCodingId
      },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
          didDrop: monitor.didDrop()
        };
      }
    }),
    [readOnly]
  );

  useEffect(() => {
    dragPreview(getEmptyImage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDragging && !findingsStore.selectionState.selectedExamples.length) {
      findingsStore.selectionState.setDraggedExample(eventDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  useEffect(() => {
    rebuildTooltips();
  }, [
    eventDetails?.freeText,
    isCheckboxDisabled,
    eventDetails.eventCodingId,
    isActionsAvailable,
    horizontalAlignment,
    onDelete
  ]);

  const renderCheckbox = useCallback(
    () => (
      <div>
        <Checkbox
          className={classnames(styles.checkbox, {
            [styles.hidden]: isCheckboxHidden
          })}
          onChange={(e) => {
            onSelect(e, eventDetails.eventId);
          }}
          checked={isSelected}
          disabled={isCheckboxDisabled}
          tabIndex={-1}
        />
      </div>
    ),
    [
      eventDetails.eventId,
      isCheckboxDisabled,
      isCheckboxHidden,
      isSelected,
      onSelect
    ]
  );

  const renderTooltipContent = useCallback(
    () =>
      isCheckboxDisabled ? (
        t("Can't select both findings & examples")
      ) : (
        <span>
          {t('Select example')}
          {/* <TextBadge
            className={styles['text-badge']}
            title={shortcutsBaseTitles.SPACE}
          /> */}
        </span>
      ),
    [isCheckboxDisabled, t]
  );

  useEffect(() => {
    activeExampleRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  }, [isActive]);

  return (
    <div style={{ opacity: isDragging ? 0 : 1 }}>
      <div
        data-testid='class-check'
        tabIndex={0}
        onDoubleClick={() =>
          onDoubleClick(eventDetails.studyId, eventDetails.eventId)
        }
        ref={isActive ? activeExampleRef : null}
        className={classnames(
          styles.example,
          {
            [styles['is-selected']]: isSelected,
            [styles['is-active']]: isActive,
            [styles['horizontal-alignment']]: horizontalAlignment
          },
          className
        )}
      >
        <div className={styles['inner-wrap']}>
          {isCheckboxDisabled || !eventDetails.eventCodingId ? (
            <Tooltip
              contentClassName={styles['tooltip-content']}
              offset={{ bottom: -5 }}
              data={[
                {
                  mainTooltip: renderTooltipContent()
                }
              ]}
            >
              {renderCheckbox()}
            </Tooltip>
          ) : (
            renderCheckbox()
          )}

          <div
            className={styles['text-container']}
            ref={drag}
            data-testid='example-draggable-area'
          >
            <span className={styles.date}>{eventDate}</span>
            {eventDetails?.freeText && (
              <div className={styles.tooltip}>
                <Tooltip
                  offset={{ bottom: -5 }}
                  data={[
                    {
                      mainTooltip: eventDetails.freeText
                    }
                  ]}
                >
                  <p className={styles.note}>{eventDetails.freeText}</p>
                </Tooltip>
              </div>
            )}
          </div>
          {isActionsAvailable && (
            <div
              className={styles['btns-container']}
              data-testid='examples-actions-panel'
            >
              <Tooltip
                contentClassName={styles['tooltip-content']}
                data={[
                  {
                    mainTooltip: (
                      <span>
                        {t(
                          eventDetails.eventCodingId ? 'Reclassify' : 'Classify'
                        )}
                        {/* <TextBadge
                          title={t('C')}
                          className={styles['text-badge']}
                        /> */}
                      </span>
                    )
                  }
                ]}
              >
                <Button
                  data-testid={EXAMPLE_BTN_CLASSIFY}
                  theme={ButtonTheme.SecondaryTransparent}
                  className={styles['classify']}
                  icon={IconType.Classify}
                  iconClassName={styles['icon-classify']}
                  onClick={() => onClassify(eventDetails.eventId)}
                  tabIndex={-1}
                />
              </Tooltip>

              {horizontalAlignment && onDelete && (
                <Tooltip
                  contentClassName={styles['tooltip-content']}
                  data={[
                    {
                      mainTooltip: (
                        <span>
                          {t('Delete')}
                          {/* <TextBadge
                            title={t(shortcutsBaseTitles.DELETE)}
                            className={styles['text-badge']}
                          /> */}
                        </span>
                      )
                    }
                  ]}
                >
                  <Button
                    data-testid={EXAMPLE_BTN_DELETE}
                    theme={ButtonTheme.CautionTransparent}
                    className={styles['delete']}
                    icon={IconType.Close}
                    iconClassName={styles['icon-delete']}
                    onClick={() => onDelete(eventDetails.eventId)}
                    tabIndex={-1}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
