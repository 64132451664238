import { ApiError } from 'entities/ApiError.entity';
import { AuthToken } from 'entities/AuthToken.entity';
import { Me } from 'entities/Me.entity';
import { UnknownError } from 'entities/UnknownError.entity';
import { RealTimeUpdateReceiveMessages } from 'enums/RealTimeUpdateType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { action, computed, makeObservable, observable } from 'mobx';
import { RootApi } from 'services/API/Root/RootApi';
import { AsyncStorage } from 'services/AsyncStorage';
import { RTUManager, UserRTUData } from 'services/RealTimeUpdatesManager';
import { SessionStorage } from 'services/SessionStorage';
import { resetStores, stores } from 'stores';
import { BaseStore } from 'stores/BaseStore';
import { AUTH_TOKEN_KEY } from 'utils/constants';

export class AuthStore implements BaseStore {
  @observable me!: Me;

  @observable meLoading!: boolean;
  @observable meError?: ApiError | UnknownError;

  @observable accessToken!: string;
  @observable authLoading!: boolean;
  @observable authError?: ApiError | UnknownError;

  @observable loadingStudyFromReader!: boolean;

  constructor() {
    makeObservable(this);
    this.reset();

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.ReaderConnected,
        callback: (data) => this.reconnectReader(data)
      },
      {
        message: RealTimeUpdateReceiveMessages.ReaderAdminConnected,
        callback: () => {
          stores[StoreType.RealTimeUpdates].updateRTUConfig({
            isReaderAdminConnected: true
          });
        }
      },
      {
        message: RealTimeUpdateReceiveMessages.UserChanged,
        callback: (data) => this.changeUser(data)
      }
    ]);
  }

  @action
  private changeUser(data: UserRTUData) {
    const activeStudyIds =
      stores[StoreType.RealTimeUpdates].realTimeUpdatesConfig?.activeStudyIds;

    AsyncStorage.reset();
    SessionStorage.reset();

    stores[StoreType.RealTimeUpdates].updateRTUConfig({
      activeStudyIds
    });

    this.setToken(data.authToken);

    window.location.reload();
  }

  @action
  private reconnectReader(data: UserRTUData) {
    AsyncStorage.reset();
    SessionStorage.reset();

    this.setToken(data.authToken);

    window.location.reload();
  }

  @action
  private setToken(data: AuthToken) {
    const token = AuthToken.deserialize(data);

    if (token.temporarySession) {
      sessionStorage.setItem(AUTH_TOKEN_KEY, token.accessToken);
    } else {
      localStorage.setItem(AUTH_TOKEN_KEY, token.accessToken);
    }
  }

  @action reset() {
    this.accessToken =
      sessionStorage.getItem(AUTH_TOKEN_KEY) ||
      localStorage.getItem(AUTH_TOKEN_KEY) ||
      '';

    this.me = Me.deserialize({});
    this.meLoading = false;
    this.meError = undefined;
    this.authLoading = false;
    this.authError = undefined;
    this.loadingStudyFromReader = false;
  }

  @computed
  get isAuthenticated() {
    return !!this.accessToken;
  }

  @action
  private async authorize(
    authenticator: () =>
      | ReturnType<typeof RootApi.login>
      | ReturnType<typeof RootApi.loginExternal>
  ) {
    this.authError = undefined;
    this.authLoading = true;

    try {
      const { data } = await authenticator();

      this.accessToken = data.accessToken;
      this.setToken(data);

      stores[StoreType.Messages].clearPageErrors();
    } catch (error) {
      this.authError = ApiError.deserializeFromCatch(error);
      stores[StoreType.Messages].addMsgError(
        `authorize_${Date.now()}`,
        ApiError.deserializeFromCatch(error)
      );
    } finally {
      this.authLoading = false;
    }
  }

  @action
  async authorizeFromCredentials(userName: string, password: string) {
    AsyncStorage.reset();
    SessionStorage.reset();
    await this.authorize(() => RootApi.login(userName, password));
  }

  @action
  async authorizeFromExternal(token?: string | null) {
    if (!token) {
      return;
    }

    this.unauthorize(false);

    await this.authorize(() => RootApi.loginExternal(token));
  }

  @action
  unauthorize(shouldClearObservers = true) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    sessionStorage.removeItem(AUTH_TOKEN_KEY);
    AsyncStorage.reset();
    SessionStorage.reset();
    resetStores();
    RTUManager.closeConnection(shouldClearObservers);
  }

  @action
  async loadMe() {
    if (!this.isAuthenticated && !this.me.email) {
      return;
    }

    this.meLoading = true;
    try {
      const { data } = await RootApi.loadMe();
      this.me = Me.deserialize(data);

      RTUManager.createConnection(this.accessToken);
      stores[StoreType.RealTimeUpdates].loadRTUConfig();
    } catch (e) {
      this.meError = ApiError.deserializeFromCatch(e);
    } finally {
      this.meLoading = false;
    }
  }

  @action
  setLoadingStudyFromReaderStatus(status: boolean) {
    this.loadingStudyFromReader = status;
  }
}
