import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form/dist/index.ie11';
import { EventPropertyTypeCategorical } from 'entities/EventPropertyTypeCategorical.entity';
import { EventPropertyCodingsData } from 'stores/finding-properties';

export const useNotScoredSelected = (
  categoricalPropertyTypes: EventPropertyTypeCategorical[],
  control: Control<{ eventPropertyCodings: EventPropertyCodingsData }>
): boolean => {
  const notScoredCodeId = useMemo(() => {
    for (const categoricalPropertyType of categoricalPropertyTypes) {
      const code = categoricalPropertyType.propertyCodes.find(
        (code) => code.isNotScoredProperty
      );
      if (code) {
        return `${code.eventPropertyCodeId}`;
      }
    }

    return undefined;
  }, [categoricalPropertyTypes]);

  const categoricalRadioButtons = useWatch({
    control,
    name: categoricalPropertyTypes.map(
      ({ propertyType }) =>
        `eventPropertyCodings.categorical.${propertyType.eventPropertyTypeId}.radio`
    )
  });

  return useMemo(
    () =>
      !!Object.values(categoricalRadioButtons).find(
        (radioButton) => radioButton === notScoredCodeId
      ),
    [categoricalRadioButtons, notScoredCodeId]
  );
};
