import { deserialize, serializable } from 'serializr';
import { REPORT_DEFAULT_FLEX_WIDTH } from 'utils/constants';

export class UserSettingsConfig {
  @serializable
  reportCollapsed?: boolean = false;

  @serializable
  reportSize?: number = REPORT_DEFAULT_FLEX_WIDTH;

  @serializable
  propertiesSize?: number = 0;

  @serializable
  unclassifiedExamplesCollapsed?: boolean = false;

  @serializable
  skipPropertiesWarnings?: boolean;

  @serializable
  skipReportWarnings?: boolean;

  @serializable
  deleteFinding?: number;

  static deserialize(json: Object | string) {
    return deserialize(UserSettingsConfig, json);
  }
}
