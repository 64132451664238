import React, { FC, useCallback, useState } from 'react';
import {
  Checkbox,
  ConfirmationModal,
  IconType,
  useCustomTranslation
} from '@holberg/ui-kit';
import { DeleteFindingOptions } from 'enums/DeleteFindingOptions.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

import { useConfirmationContext } from './FindingsConfirmationContext';

import styles from './FindingsConfirmationModal.module.scss';

interface Props {
  transparent?: boolean;
  onUpdateSettingsConfig: (userSetting: Object) => void;
}

export const FindingsConfirmationModal: FC<Props> = observer(
  ({ transparent, onUpdateSettingsConfig }) => {
    const [hideWarnings, setHideWarnings] = useState<boolean>(false);
    const { t } = useCustomTranslation();

    const confirmationContext = useConfirmationContext();
    const findingsStore = useStore(StoreType.Findings);

    const onSubmit = useCallback(() => {
      if (
        hideWarnings &&
        confirmationContext?.state.modalContent === 'deleteFindingConfirmation'
      ) {
        onUpdateSettingsConfig({
          deleteFinding: DeleteFindingOptions.AlwaysDelete
        });
      } else if (hideWarnings) {
        onUpdateSettingsConfig({
          skipPropertiesWarnings: hideWarnings
        });
      }
      confirmationContext!.state.onSubmit!();
      setHideWarnings(false);
      confirmationContext!.onClose();
    }, [confirmationContext, hideWarnings, onUpdateSettingsConfig]);

    const onClose = useCallback(() => {
      if (
        hideWarnings &&
        confirmationContext?.state.modalContent === 'deleteFindingConfirmation'
      ) {
        onUpdateSettingsConfig({
          deleteFinding: DeleteFindingOptions.NeverDelete
        });
      }
      setHideWarnings(false);
      confirmationContext?.onClose();
      findingsStore.selectionState?.discardSelections();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationContext, hideWarnings]);

    const toggleHideWarnings = useCallback(() => {
      setHideWarnings((state) => !state);
    }, []);

    if (!confirmationContext) {
      return null;
    }

    const renderModalContent = () => {
      switch (confirmationContext?.state.modalContent) {
        case 'deleteFindingConfirmation':
          return (
            <Checkbox
              data-testId='remember-user-setting'
              checked={hideWarnings}
              label={t(
                'Remember my selection and apply it to all findings for which last example is removed in the future.'
              )}
              onChange={toggleHideWarnings}
            />
          );
        default:
          return (
            <Checkbox
              checked={hideWarnings}
              label={t('Don’t show me this message again.')}
              onChange={toggleHideWarnings}
            />
          );
      }
    };

    return (
      <ConfirmationModal
        onCancel={onClose}
        onSubmit={onSubmit}
        cancelButtonTitle={
          confirmationContext?.state.cancelButtonTitle || t('Cancel')
        }
        submitButtonTitle={confirmationContext?.state.submitButtonTitle!}
        visible={confirmationContext?.state.isOpen}
        transparent={transparent}
        icon={IconType.Warning}
        handleVisible={onClose}
        title={confirmationContext?.state.title}
      >
        <div className={styles.content} data-testid='confirmation-modal'>
          {renderModalContent()}
        </div>
      </ConfirmationModal>
    );
  }
);
