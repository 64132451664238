export enum RealTimeUpdateReceiveMessages {
  OpenStudy = 'OpenStudy',
  CloseStudy = 'CloseStudy',
  StudyUpdated = 'StudyUpdated',
  StudyListUpdated = 'StudyListUpdated',
  PatientUpdated = 'PatientUpdated',
  PatientListUpdated = 'PatientListUpdated',
  EventUpdated = 'EventUpdated',
  EventDeleted = 'EventDeleted',
  EventAdded = 'EventAdded',
  ActivateEvent = 'ActivateEvent',
  EventNotFound = 'EventNotFound',
  ReaderDisconnected = 'ReaderDisconnected',
  ReaderConnected = 'ReaderConnected',
  UpdatePatientLockStatus = 'UpdatePatientLockStatus',
  ReaderAdminConnected = 'ReaderAdminConnected',
  ReaderAdminDisconnected = 'ReaderAdminDisconnected',
  UserChanged = 'UserChanged',
  StudyActiveState = 'StudyActiveState', //block
  ShowNotification = 'ShowNotification'
}
export enum RealTimeUpdateSendMessages {
  ActivateEvent = 'ActivateEvent',
  OpenStudy = 'OpenStudy',
  EventNotFound = 'EventNotFound',
  GetPatientLockStatus = 'GetPatientLockStatus',
  RequestPatientEditLock = 'RequestPatientEditLock',
  RefreshPatientEditLock = 'RefreshPatientEditLock',
  ReleasePatientEditLock = 'ReleasePatientEditLock',
  PatientOpened = 'PatientOpened',
  PatientClosed = 'PatientClosed',
  RequestStudyEditLock = 'RequestStudyEditLock' //block
}
