import { AuthToken } from 'entities/AuthToken.entity';
import { Me } from 'entities/Me.entity';
import { Phrase } from 'entities/Phrase.entity';

import { ApiBase } from '../ApiBase';

class RootApi extends ApiBase {
  login(userName: string, password: string) {
    return this.client.post<AuthToken>('/account/login', {
      userName,
      password
    });
  }

  loginExternal(token: string) {
    return this.client.post<AuthToken>('/account/token', {
      token
    });
  }

  loadMe() {
    return this.client.get<Me>('/me');
  }

  loadTranslation() {
    return this.client.get<Phrase[]>('/translation');
  }

  loadSite() {
    return this.client.get<Me>('/site');
  }
}

const instance = new RootApi('/api/v1');

export { instance as RootApi };
