import { SelectOptionType } from '@holberg/ui-kit';
import { CategoricalPropertyCode } from 'entities/CategoricalPropertyCode.entity';
import { computed, makeObservable } from 'mobx';
import { date, deserialize, object, serializable } from 'serializr';

import { CategoricalPropertyType } from './CategoricalPropertyType.entity';

type OptionType = SelectOptionType | null | undefined;

export class CategoricalPropertyCoding {
  @serializable
  propertyCodingId: number = 0;

  @serializable
  freeText: string = '';

  @serializable
  userId: number = 0;

  @serializable
  isActive: boolean = true;

  @serializable(date())
  start?: Date;

  @serializable(date())
  stop?: Date;

  @serializable
  siteId: number = 0;

  @serializable
  studyId: number = 0;

  @serializable(object(CategoricalPropertyCode))
  propertyCodeModel: CategoricalPropertyCode = new CategoricalPropertyCode();

  @serializable(object(CategoricalPropertyType))
  propertyTypeModel: CategoricalPropertyType = new CategoricalPropertyType();

  constructor() {
    makeObservable(this);
  }

  @computed
  get name(): string {
    return this.propertyCodeModel?.translatedName.eitherValue;
  }

  @computed
  get selectOptionShape(): OptionType {
    if (this.propertyCodeModel?.name) {
      const {
        translatedName,
        propertyCodeId,
        code,
        isExclusiveInMultiSelect
      } = this.propertyCodeModel;

      return {
        label: translatedName.eitherValue,
        value: String(propertyCodeId),
        code: code,
        isMultiSelect: !isExclusiveInMultiSelect
      };
    }

    return null;
  }

  @computed
  get formShape(): {
    freeText?: string;
    value?: OptionType;
  } {
    return {
      freeText: this.freeText || '',
      value: this.selectOptionShape
    };
  }

  static deserialize(json: Object | string) {
    return deserialize(CategoricalPropertyCoding, json);
  }

  static deserializeAsMap(
    list: CategoricalPropertyCoding[]
  ): Record<string | number, CategoricalPropertyCoding[]> {
    return list.reduce((acc, categoricalProperty) => {
      const key = categoricalProperty.propertyTypeModel.propertyTypeId;

      if (acc[key]) {
        return {
          ...acc,
          [key]: [
            ...acc[key],
            CategoricalPropertyCoding.deserialize(categoricalProperty)
          ]
        };
      }
      return {
        ...acc,
        [key]: [CategoricalPropertyCoding.deserialize(categoricalProperty)]
      };
    }, {});
  }

  static deserializeAsList(
    list: CategoricalPropertyCoding[]
  ): CategoricalPropertyCoding[] {
    return list.map(CategoricalPropertyCoding.deserialize);
  }
}
