import { StoreType } from 'enums/StoreType.enum';

import { useStore } from './store';

export const useCurrentUserHasLock = () => {
  const patientLockDetailsStore = useStore(StoreType.PatientLockDetails);
  const authStore = useStore(StoreType.Auth);

  const currentUserHasLock = (patientId: number) => {
    const patientLockDetails = patientLockDetailsStore.patientLockDetails.get(
      patientId
    );
    return patientLockDetails?.userInfo?.userId === authStore.me.userId;
  };

  return { currentUserHasLock };
};
