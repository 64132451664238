export enum ReaderErrorType {
  ReaderDisconnected = 'readerDisconnected',
  ReaderAuthFailed = 'readerAuthFailed',
  NotFoundEventEEG = 'notFoundEventEEG',
  NotFoundEventApp = 'notFoundEventApp'
}

export const ReaderErrorMessage = Object.freeze({
  [ReaderErrorType.ReaderDisconnected]:
    'The connection with the EEG software ended. You can now close the window.',
  [ReaderErrorType.ReaderAuthFailed]:
    'Oops, hiSCORE could not be opened. Please close the window and retry.',
  [ReaderErrorType.NotFoundEventApp]: 'Can not found this event',
  [ReaderErrorType.NotFoundEventEEG]:
    'This event could not be opened in the EEG. Please try again. If the issue persists - reopen the report via the EEG software.'
});
