import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Input,
  LoadingOverlay,
  SystemMessage,
  SystemMessageTheme,
  useCustomTranslation
} from '@holberg/ui-kit';
import { SnackBar } from 'components/SnackBar';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

import styles from './LoginPage.module.scss';

export const LoginPage: React.FC = observer(() => {
  const { t } = useCustomTranslation();

  const history = useHistory();
  const authStore = useStore(StoreType.Auth);
  const messagesStore = useStore(StoreType.Messages);

  const [loginValue, setLoginValue] = useState<string>('');
  const [passwordValue, setPasswordValue] = useState<string>('');

  const login = useCallback(async () => {
    if (loginValue && passwordValue) {
      await authStore.authorizeFromCredentials(loginValue, passwordValue);
      history.replace(Routes.Patients);
    }
  }, [authStore, history, loginValue, passwordValue]);

  const clearUnauthorizeError = useCallback(() => {
    messagesStore.clearUnauthorizeError();
  }, [messagesStore]);

  return (
    <div className={styles['login-page']}>
      <SnackBar />
      <LoadingOverlay loading={authStore.authLoading}>
        <div className={styles.container}>
          <span className={styles.title}>Login</span>
          <Input
            inputClassName={styles.login}
            name='login'
            placeholder='Login'
            value={loginValue}
            onChange={(e) => {
              setLoginValue(e.target.value);
            }}
          />
          <Input
            inputClassName={styles.password}
            name='password'
            type='password'
            placeholder='Password'
            value={passwordValue}
            onKeyUp={(e) => e.keyCode === 13 && login()}
            onChange={(e) => {
              setPasswordValue(e.target.value);
            }}
          />
          <Button
            title='Submit'
            className={styles.submit}
            onClick={login}
            disabled={!loginValue || !passwordValue || authStore.authLoading}
          />
        </div>
      </LoadingOverlay>
      <SystemMessage
        theme={SystemMessageTheme.Error}
        visible={
          !messagesStore.hasConnectionIssues && !!messagesStore.unauthorizeError
        }
        title={t(messagesStore.unauthorizeError?.fullMessage!)}
        onClose={clearUnauthorizeError}
      />
    </div>
  );
});
