import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  NavTab,
  NavTabTheme,
  useCustomTranslation,
  UserHeading
} from '@holberg/ui-kit';
import logo from 'assets/logo.png';
import cn from 'classnames';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';

import styles from './NavigationHeader.module.scss';

interface Props {
  className?: string;
}

export const NavigationHeader: React.FC<Props> = observer(({ className }) => {
  const authStore = useStore(StoreType.Auth);
  const siteStore = useStore(StoreType.Site);

  const { t } = useCustomTranslation();

  useEffect(() => {
    if (!authStore.me.email) {
      authStore.loadMe();
      siteStore.loadSite();
    }
  }, [authStore, siteStore]);

  return (
    <header className={cn(styles['navigation-header'], className)}>
      <img src={logo} alt='holberg logo' data-testid='logo' />
      <div className={styles.tabs} data-testid='navigation'>
        <NavTab
          label={t('Patients')}
          component={NavLink}
          className={styles.tab}
          activeClassName={NavTab.activeStyle}
          to={Routes.Patients}
          exact
          theme={NavTabTheme.Underlined}
        />
      </div>
      {!authStore.meLoading && (
        <div className={styles.controls}>
          <UserHeading
            className={styles['user-heading']}
            name={authStore.me.fullName}
          />
        </div>
      )}
    </header>
  );
});
