import React, { FocusEvent } from 'react';
import {
  Controller,
  useFormContext,
  useWatch
} from 'react-hook-form/dist/index.ie11';
import { FormInput, FormUnitInput, InputUnitConfig } from '@holberg/ui-kit';
import cn from 'classnames';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';

import {
  gestationalAgeDaysAllowedCharts,
  gestationalAgeWeeksAllowedCharts,
  maxDaysAmount,
  maxWeeksAmount,
  minDaysAmount,
  minWeeksAmount
} from '../GeneralPatientDetails/constants';

import styles from './GestationalAgeRow.module.scss';

const weeksUnitConfig = new InputUnitConfig(
  {
    min: minWeeksAmount,
    max: maxWeeksAmount
  },
  [gestationalAgeWeeksAllowedCharts]
);

const daysUnitConfig = new InputUnitConfig(
  {
    min: minDaysAmount,
    max: maxDaysAmount
  },
  [gestationalAgeDaysAllowedCharts]
);

interface Props {
  readOnly?: boolean;
  onPropertyBlur?: (accessor: string, propertyName: string) => void;
}

export const GestationalAgeRow: React.FC<Props> = ({
  readOnly = false,
  onPropertyBlur = () => {}
}) => {
  const { control, setValue, getValues } = useFormContext<{
    patientDetails: PatientDetails;
    patientCodings: Record<number, CategoricalPropertyCoding['formShape'][]>;
  }>();

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (
      !event.target.value &&
      getValues('patientDetails.gestationalAgeInDaysExceedingWeeks')
    ) {
      setValue('patientDetails.gestationalAgeInDaysExceedingWeeks', undefined);
      onPropertyBlur(
        'patientDetails.gestationalAgeInDaysExceedingWeeks',
        'gestationalAgeInDaysExceedingWeeks'
      );
    }
  };

  const gestationalAgeInWeeks = useWatch({
    control,
    name: 'patientDetails.gestationalAgeInWeeks'
  });

  const gestationalAgeInDays = useWatch({
    control,
    name: 'patientDetails.gestationalAgeInDaysExceedingWeeks'
  });

  if (readOnly && !gestationalAgeInWeeks && !gestationalAgeInDays) {
    return <FormInput multiline readOnly value='-' />;
  }

  return (
    <>
      {readOnly && !gestationalAgeInWeeks ? null : (
        <span
          className={cn(styles['unit-input'], readOnly && styles['read-only'])}
        >
          <Controller
            control={control}
            name='patientDetails.gestationalAgeInWeeks'
            defaultValue=''
            render={({ value, ...rest }) => (
              <FormUnitInput
                {...rest}
                readOnly={readOnly}
                placeholder='20-45'
                onBlur={(e) => {
                  rest.onBlur();
                  onBlur(e);
                  onPropertyBlur(
                    'patientDetails.gestationalAgeInWeeks',
                    'gestationalAgeInWeeks'
                  );
                }}
                value={value || ''}
                title='week(s)'
                unitConfig={weeksUnitConfig}
              />
            )}
          />
        </span>
      )}
      {readOnly && !gestationalAgeInDays ? null : (
        <span
          className={cn(styles['unit-input'], readOnly && styles['read-only'])}
        >
          <Controller
            control={control}
            name='patientDetails.gestationalAgeInDaysExceedingWeeks'
            defaultValue=''
            render={({ value, ...rest }) => (
              <FormUnitInput
                {...rest}
                placeholder='0-6'
                readOnly={readOnly}
                disabled={!gestationalAgeInWeeks}
                value={value || ''}
                title='day(s)'
                onBlur={() =>
                  onPropertyBlur(
                    'patientDetails.gestationalAgeInDaysExceedingWeeks',
                    'gestationalAgeInDaysExceedingWeeks'
                  )
                }
                unitConfig={daysUnitConfig}
              />
            )}
          />
        </span>
      )}
    </>
  );
};
