import React, { useMemo } from 'react';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Checkbox,
  Icon,
  IconType,
  KeyValueRow,
  KeyValueRowDirection,
  StatusBadge,
  StatusBadgeTheme,
  Tooltip,
  useCustomTranslation
} from '@holberg/ui-kit';
import { TooltipTemplate } from 'components/TooltipTemplate';
import { Report } from 'entities/Report.entity';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { observer } from 'mobx-react-lite';
import {
  MD_SCREEN_MAX_WIDTH,
  REPORT_INFO_BOTTOM_VIEW_MAX_WIDTH
} from 'utils/constants';

import styles from './ReportTileHeader.module.scss';

interface Props {
  moveRecordings: (reportId: number) => void;
  reopenCompletedReport: (reportId: number) => void;
  onReportToggle: (reportId: number) => void;
  deleteReport: (reportId: number) => void;
  report: Report;
  isDroppable?: boolean;
  isLocked: boolean;
}

export const ReportTileHeader: React.FC<Props> = observer(
  ({
    report,
    isDroppable,
    moveRecordings,
    reopenCompletedReport,
    deleteReport,
    onReportToggle,
    isLocked = true
  }) => {
    const reportId = report.description!.descriptionId;
    const patientReportsStore = useStore(StoreType.PatientReports);
    const { windowWidth } = useWindowWidth();
    const hideReportInfo = useMemo(
      () => windowWidth < REPORT_INFO_BOTTOM_VIEW_MAX_WIDTH,
      [windowWidth]
    );
    const isSelectable =
      patientReportsStore.selectedReportedRecordings.size === 0 ||
      !report.studies.some((study) => !study.isOnline);
    const selectionSize =
      patientReportsStore.selectedReportedRecordings.size +
      patientReportsStore.selectedReports.size +
      patientReportsStore.selectedUnreportedRecordings.size;

    const { t } = useCustomTranslation();
    return (
      <>
        <div className={styles['report-tile-header']}>
          <div className={styles['report-tile-info-container']}>
            <div className={styles['report-status']}>
              {!isLocked && (
                <Checkbox
                  className={styles['report-checkbox']}
                  onChange={() => onReportToggle(reportId)}
                  disabled={!isSelectable}
                  checked={patientReportsStore.selectedReports.has(reportId)}
                  data-testid='report-checkbox'
                />
              )}
              <span>{report.reportStartDate}</span>
              <StatusBadge
                theme={
                  report.description?.isCompleted
                    ? StatusBadgeTheme.Green
                    : StatusBadgeTheme.Blue
                }
                title={report.description!.descriptionStatus.eitherValue}
              />
            </div>
            {!hideReportInfo && <ReportTileInfo report={report} />}
          </div>
          {!isLocked && (
            <div className={styles['report-actions']}>
              {isDroppable && (
                <Tooltip
                  delayTime={500}
                  data={[
                    {
                      mainTooltip: t('Move here')
                    }
                  ]}
                >
                  <Button
                    size={ButtonSize.Regular}
                    theme={ButtonTheme.Secondary}
                    icon={IconType.Subdirectory}
                    onClick={() => moveRecordings(reportId)}
                  />
                </Tooltip>
              )}
              {selectionSize === 0 && (
                <>
                  {report.description?.isCompleted && (
                    <Tooltip
                      delayTime={500}
                      data={[
                        {
                          mainTooltip: t('Reopen report')
                        }
                      ]}
                    >
                      <Button
                        size={ButtonSize.Regular}
                        theme={ButtonTheme.Secondary}
                        icon={IconType.Edit}
                        data-testid='edit-report-btn'
                        onClick={() => reopenCompletedReport(reportId)}
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    delayTime={500}
                    data={[
                      {
                        mainTooltip: t('Delete report')
                      }
                    ]}
                  >
                    <Button
                      size={ButtonSize.Regular}
                      theme={ButtonTheme.Secondary}
                      icon={IconType.Trash}
                      data-testid='delete-report-btn'
                      onClick={() => deleteReport(reportId)}
                    />
                  </Tooltip>
                </>
              )}
            </div>
          )}
        </div>
        {hideReportInfo && <ReportTileInfo report={report} />}
      </>
    );
  }
);

const ReportTileInfo: React.FC<{ report: Report }> = ({ report }) => {
  const { t } = useCustomTranslation();
  const reportId = report.description!.descriptionId;
  const { windowWidth } = useWindowWidth();
  const verticalKeyValue = useMemo(() => windowWidth < MD_SCREEN_MAX_WIDTH, [
    windowWidth
  ]);
  const renderHeaderRow = (title: string, value?: string | number) =>
    !!value && (
      <KeyValueRow
        title={t(title)}
        value={value}
        className={styles.row}
        direction={
          verticalKeyValue
            ? KeyValueRowDirection.Vertical
            : KeyValueRowDirection.Horizontal
        }
        reverse={verticalKeyValue ? true : false}
      />
    );
  return (
    <div className={styles['report-tile-info']}>
      <TooltipTemplate
        tooltipData={[
          [
            {
              title: 'Start',
              value: report.reportStartDateTime
            },
            {
              title: 'Stop',
              value: report.reportStopDateTime
            }
          ],
          [
            {
              title: 'Total time',
              value: report.totalTime
            }
          ],
          [
            {
              title: 'ID',
              value: reportId.toString()
            }
          ]
        ]}
        badgeContent={{
          badgeLabel: 'Double-click',
          badgeSubLabel: 'to open the report details'
        }}
      >
        <Icon iconType={IconType.Info} className={styles['report-info-icon']} />
      </TooltipTemplate>
      {renderHeaderRow('Total time', report.totalTime)}
      {renderHeaderRow('EEG Type', report.description?.eegType)}
      {renderHeaderRow('Report ID', reportId)}
      {renderHeaderRow('Revision', report.description?.revision || '0')}
    </div>
  );
};
